import React from "react";
import AboutPic from "../../ASSETS/about-pic.png";
import "./About.css";

const About = () => {
  return (
    <div className="container">
      <section id="about">
        <div className="about-heading">
          <h5>Get to know</h5>
          <h2>About Me</h2>
        </div>

        <div className="row about-body justify-contents-center align-items-center">
          <div className="col-9 col-lg-4 about-pic mb-3 mb-lg-0">
            <img src={AboutPic} alt="" className="aboutimg" />
          </div>

          <div className="col-12 col-lg-8">
            <div className="row about-cards">
              <div className="col-12 col-sm-3 about-card">
                <i className="fa-solid fa-briefcase"></i>
                <h5>Experience</h5>
                <small>3+ Years Working</small>
              </div>
              <div className="col-12 col-sm-3 about-card">
                <i className="fa-solid fa-people-group"></i>
                <h5>Clients</h5>
                <small>200+ worlwide</small>
              </div>
              <div className="col-12 col-sm-3 about-card">
                <i className="fa-solid fa-list-check"></i>
                <h5>Projects</h5>
                <small>80+ Completed</small>
              </div>
              <div className="col-12 text-center my-3">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto
                dignissimos quis laudantium sunt qui? Dolorum eaque atque
                eveniet ipsam facere magni rem explicabo quia, pariatur, tempora
                tempore quae, incidunt sit?
              </div>
              <div className="col-12 text-center">
                <a className="btn btn-primary" href="#contact">
                  lets's talk
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
