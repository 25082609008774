import React from "react";

const HeaderSocials = () => {
  return (
    <div className="header_socials">
      <a href="https://www.linkedin.com" target="_blank" rel="noreferrer">
        <i className="fa-brands fa-linkedin"></i>
      </a>
      <a href="https://www.github.com" target="_blank" rel="noreferrer">
        <i className="fa-brands fa-github"></i>
      </a>
      <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
        <i className="fa-brands fa-facebook-square"></i>
      </a>
    </div>
  );
};

export default HeaderSocials;
