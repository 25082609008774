import React from "react";
import "./Services.css";

const Services = () => {
  return (
    <div className="container">
      <section id="services">
        <div className="service-heading text-center">
          <h5>What I Offer</h5>
          <h2>Services</h2>
        </div>
        <div className="row service-cards">
          <div className="col-12 col-xl-3 service-card">
            <div className="service-card-header">Web Developement</div>
            <div className="lists">
              <li>
                <i className="fa-solid fa-hand-point-right"></i> Lorem ipsum
                dolor, sit amet consectetur adipisicing elit. Quia, nemo.
              </li>
              <li>
                <i className="fa-solid fa-hand-point-right"></i> Lorem ipsum
                dolor, sit amet consectetur adipisicing elit. Quia, nemo.
              </li>
              <li>
                <i className="fa-solid fa-hand-point-right"></i> Lorem ipsum
                dolor, sit amet consectetur adipisicing elit. Quia, nemo.
              </li>
              <li>
                <i className="fa-solid fa-hand-point-right"></i> Lorem ipsum
                dolor, sit amet consectetur adipisicing elit. Quia, nemo.
              </li>
              <li>
                <i className="fa-solid fa-hand-point-right"></i> Lorem ipsum
                dolor, sit amet consectetur adipisicing elit. Quia, nemo.
              </li>
            </div>
          </div>

          <div className="col-12 col-xl-3 service-card">
            <div className="service-card-header">Content Creation</div>
            <div className="lists">
              <li>
                <i className="fa-solid fa-hand-point-right"></i> Lorem ipsum
                dolor, sit amet consectetur adipisicing elit. Quia, nemo.
              </li>
              <li>
                <i className="fa-solid fa-hand-point-right"></i> Lorem ipsum
                dolor, sit amet consectetur adipisicing elit. Quia, nemo.
              </li>
              <li>
                <i className="fa-solid fa-hand-point-right"></i> Lorem ipsum
                dolor, sit amet consectetur adipisicing elit. Quia, nemo.
              </li>
              <li>
                <i className="fa-solid fa-hand-point-right"></i> Lorem ipsum
                dolor, sit amet consectetur adipisicing elit. Quia, nemo.
              </li>
              <li>
                <i className="fa-solid fa-hand-point-right"></i> Lorem ipsum
                dolor, sit amet consectetur adipisicing elit. Quia, nemo.
              </li>
            </div>
          </div>

          <div className="col-12 col-xl-3 service-card">
            <div className="service-card-header">UI/UX Design</div>
            <div className="lists">
              <li>
                <i className="fa-solid fa-hand-point-right"></i> Lorem ipsum
                dolor, sit amet consectetur adipisicing elit. Quia, nemo.
              </li>
              <li>
                <i className="fa-solid fa-hand-point-right"></i> Lorem ipsum
                dolor, sit amet consectetur adipisicing elit. Quia, nemo.
              </li>
              <li>
                <i className="fa-solid fa-hand-point-right"></i> Lorem ipsum
                dolor, sit amet consectetur adipisicing elit. Quia, nemo.
              </li>
              <li>
                <i className="fa-solid fa-hand-point-right"></i> Lorem ipsum
                dolor, sit amet consectetur adipisicing elit. Quia, nemo.
              </li>
              <li>
                <i className="fa-solid fa-hand-point-right"></i> Lorem ipsum
                dolor, sit amet consectetur adipisicing elit. Quia, nemo.
              </li>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
