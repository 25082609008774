import React from "react";
import About from "./COMPONENTS/About/About";
import Contact from "./COMPONENTS/Contacts/Contact";
import Experience from "./COMPONENTS/Experience/Experience";
import Footer from "./COMPONENTS/Footer/Footer";
import Header from "./COMPONENTS/Header/Header";
import Nav from "./COMPONENTS/Nav/Nav";
import Portfolio from "./COMPONENTS/Portfolio/Portfolio";
import Services from "./COMPONENTS/Services/Services";
import Testimonials from "./COMPONENTS/Testimonials/Testimonials";

export default function App() {
  console.log("App rendered...");
  return (
    <>
      <Header />
      <Nav />
      <About />
      <Experience />
      <Services />
      <Portfolio />
      <Testimonials />
      <Contact />
      <Footer />
    </>
  );
}
