/* eslint-disable react/jsx-no-target-blank */
import emailjs from "@emailjs/browser";
import React, { useRef, useState } from "react";

import "./Contact.css";

const Contact = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_vtaahmr",
      "template_dzbumve",
      form.current,
      "wloFTW6wiQA96SDLX"
    );
    setFullName("");
    setEmail("");
    setMessage("");
  };

  return (
    <div className="container">
      <section id="contact">
        <div className="contact-heading">
          <h5>Get In Touch</h5>
          <h2>Contact Me</h2>
        </div>
        <div className="contact-body row">
          <div className="col-12 col-sm-9 col-md-6 col-lg-5 col-xl-4 emw">
            <div className="contact-options">
              <i className="fa-solid fa-envelope"></i>
              <h4>Email</h4>
              <h5>abc@gmail.com</h5>
              <a href="mailto:sad.raj256@gmail.com" target="_blank">
                Send a message
              </a>
            </div>

            <div className="contact-options">
              <i className="fa-brands fa-facebook-messenger"></i>
              <h4>Messenger</h4>
              <h5>Mr. Random Person</h5>
              <a href="https://m.me/" target="_blank">
                Send a message
              </a>
            </div>

            <div className="contact-options">
              <i className="fa-brands fa-whatsapp"></i>
              <h4>Whatsapp</h4>
              <h5>+880123456789</h5>
              <a href="https://wa.me/8801407484301" target="_blank">
                Send a message
              </a>
            </div>
          </div>
          <div className="col-12 col-lg-6 col-xl-7 contact-form">
            <form ref={form} onSubmit={sendEmail}>
              <input
                type="text"
                name="fullName"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                placeholder="Enter your full name..."
                required
              />
              <input
                type="text"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email address..."
                required
              />
              <textarea
                name="message"
                cols="30"
                rows="10"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Write your message here..."
                required
              ></textarea>
              <div className="text-center mt-4">
                <button type="submit" className="btn btn-primary">
                  Submit Now
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
