/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import IMG1 from "../../ASSETS/IMG1.jpg";
import IMG2 from "../../ASSETS/IMG2.jpg";
import IMG3 from "../../ASSETS/IMG3.jpg";
import IMG4 from "../../ASSETS/IMG4.jpg";
import IMG5 from "../../ASSETS/IMG5.jpg";
import IMG6 from "../../ASSETS/IMG6.jpg";
import IMG7 from "../../ASSETS/IMG7.jpg";
import IMG8 from "../../ASSETS/IMG8.jpg";
import IMG9 from "../../ASSETS/IMG9.jpg";
import "./Portfolio.css";

const data = [
  {
    id: 1,
    image: IMG1,
    title: "Financial Consultation Agency Landing Page",
    github: "https://www.github.com",
    demo: "https://dribbble.com/shots/18744500-Financial-Consultation-Agency-Landing-Page",
  },
  {
    id: 2,
    image: IMG2,
    title: "Furniture Studio Landing Page",
    github: "https://www.github.com",
    demo: "https://dribbble.com/shots/18744500-Financial-Consultation-Agency-Landing-Page",
  },
  {
    id: 3,
    image: IMG3,
    title: "The Agensea - Creative Agency Responsive",
    github: "https://www.github.com",
    demo: "https://dribbble.com/shots/18744500-Financial-Consultation-Agency-Landing-Page",
  },
  {
    id: 4,
    image: IMG4,
    title: "Hotel Scape Landing Page",
    github: "https://www.github.com",
    demo: "https://dribbble.com/shots/18744500-Financial-Consultation-Agency-Landing-Page",
  },
  {
    id: 5,
    image: IMG5,
    title: "The Agensea - Creative Agency Hero Section Website",
    github: "https://www.github.com",
    demo: "https://dribbble.com/shots/18744500-Financial-Consultation-Agency-Landing-Page",
  },
  {
    id: 6,
    image: IMG6,
    title: "Xavier - Web App Real Estate Dashboard ",
    github: "https://www.github.com",
    demo: "https://dribbble.com/shots/18744500-Financial-Consultation-Agency-Landing-Page",
  },
  {
    id: 7,
    image: IMG7,
    title: "Fullhouse - Real Estate Landing Page Website",
    github: "https://www.github.com",
    demo: "https://dribbble.com/shots/18744500-Financial-Consultation-Agency-Landing-Page",
  },
  {
    id: 8,
    image: IMG8,
    title: "Mobile App Travelling ~ Travelaja",
    github: "https://www.github.com",
    demo: "https://dribbble.com/shots/18744500-Financial-Consultation-Agency-Landing-Page",
  },
  {
    id: 9,
    image: IMG9,
    title: "Mobile Apps Marketplace Philosophy.clotch",
    github: "https://www.github.com",
    demo: "https://dribbble.com/shots/18744500-Financial-Consultation-Agency-Landing-Page",
  },
];

const Portfolio = () => {
  return (
    <div className="container">
      <section id="portfolio">
        <div className="portfolio-heading">
          <h5>My Recent Work</h5>
          <h2>Portfolio</h2>
        </div>
        <div className="row portfolio-body">
          {data.map(({ id, image, title, github, demo }) => (
            <div
              className="col-12 col-sm-5 col-lg-3 portfolio-item bg-dark"
              key={Math.random()}
            >
              <div className="portfolio-pic">
                <img src={image} alt="" />
              </div>
              <h6 className="portfolio-title">{title}</h6>
              <div className="portfolio-links">
                <a href={github} target="_blank">
                  Github
                </a>
                <a href={demo} target="_blank">
                  Live Demo
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Portfolio;
