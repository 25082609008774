/* eslint-disable no-unused-vars */
import React from "react";
import Client1 from "../../ASSETS/Client1.jpg";
import Client2 from "../../ASSETS/Client2.jpg";
import Client3 from "../../ASSETS/Client3.jpg";
import Client4 from "../../ASSETS/Client4.jpg";
import "./Testimonials.css";

import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const clientData = [
  {
    image: Client1,
    clientName: "Robert Barrington",
    review:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam ex, dolore labore laudantium quaerat, dolorem ipsum delectus assumenda quod ullam consequuntur qui, omnis nesciunt enim? Veritatis voluptatibus provident corporis consectetur.",
  },
  {
    image: Client2,
    clientName: "Charles Lockwood",
    review:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam ex, dolore labore laudantium quaerat, dolorem ipsum delectus assumenda quod ullam consequuntur qui, omnis nesciunt enim? Veritatis voluptatibus provident corporis consectetur.",
  },
  {
    image: Client3,
    clientName: "Steven Harmison",
    review:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam ex, dolore labore laudantium quaerat, dolorem ipsum delectus assumenda quod ullam consequuntur qui, omnis nesciunt enim? Veritatis voluptatibus provident corporis consectetur.",
  },
  {
    image: Client4,
    clientName: "Peter Moose",
    review:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam ex, dolore labore laudantium quaerat, dolorem ipsum delectus assumenda quod ullam consequuntur qui, omnis nesciunt enim? Veritatis voluptatibus provident corporis consectetur.",
  },
];

const Testimonials = () => {
  return (
    <div className="container">
      <section id="testimonials">
        <div className="testimonial-heading">
          <h5>Review From Clients</h5>
          <h2>Testimonials</h2>
        </div>
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={50}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
        >
          {clientData.map(({ image, clientName, review }) => (
            <SwiperSlide
              className="testimonial-body bg-dark text-center"
              key={Math.random()}
            >
              <div className="client-pic bg-success">
                <img src={image} alt="" />
              </div>
              <h4 className="client bh-info">{clientName}</h4>
              <small className="client-review">{`" ${review} "`}</small>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </div>
  );
};

export default Testimonials;
