/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "./Nav.css";

const Nav = () => {
  const [active, setActive] = useState();

  return (
    <div className="container">
      <nav>
        <a
          href="#"
          onClick={() => setActive("#home")}
          className={active === "#home" ? "active" : null}
        >
          <i className="fa-solid fa-house"></i>
        </a>
        <a
          href="#about"
          onClick={() => setActive("#about")}
          className={active === "#about" ? "active" : null}
        >
          <i className="fa-solid fa-user"></i>
        </a>
        <a
          href="#experience"
          onClick={() => setActive("#experience")}
          className={active === "#experience" ? "active" : null}
        >
          <i className="fa-solid fa-briefcase"></i>
        </a>
        <a
          href="#services"
          onClick={() => setActive("#services")}
          className={active === "#services" ? "active" : null}
        >
          <i className="fa-solid fa-hand-holding-heart"></i>
        </a>
        <a
          href="#contact"
          onClick={() => setActive("#contact")}
          className={active === "#contact" ? "active" : null}
        >
          <i className="fa-solid fa-address-book"></i>
        </a>
      </nav>
    </div>
  );
};

export default Nav;
