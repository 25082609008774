import React from "react";
import ME from "../../ASSETS/DP.png";
import CTA from "./CTA";
import "./Header.css";
import HeaderSocials from "./HeaderSocials";

const Header = () => {
  return (
    <div className="container">
      <header id="home">
        <div className="header_container">
          <h5>Hello I'm</h5>
          <h1 className="my-3 ">Mr. Random Person</h1>
          <h5 className="text-light">Fullstack Web Developer</h5>
          <CTA />
          <HeaderSocials />
          <div className="me">
            <img src={ME} alt="" className="img-fluid" />
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
