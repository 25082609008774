import React from "react";
import "./Experience.css";

const Experience = () => {
  // <i class="fa-solid fa-circle-star"></i>
  return (
    <div className="container">
      <section id="experience">
        <div className="experience-heading text-center">
          <h5>What Skills I Have</h5>
          <h2>My Experience</h2>
        </div>
        <div className="row experience-body">
          <div className="col-12 col-md-5 frontend">
            <h5 className="text-center my-4">Frontend Developement</h5>
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="langus">
                  <li>
                    <i className="fa-solid fa-star-of-life"></i>HTML
                  </li>
                  <small>Experienced</small>
                </div>

                <div className="langus">
                  <li>
                    <i className="fa-solid fa-star-of-life"></i>Javascript
                  </li>
                  <small>Experienced</small>
                </div>

                <div className="langus">
                  <li>
                    <i className="fa-solid fa-star-of-life"></i>Talwind
                  </li>
                  <small>Experienced</small>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="langus">
                  <li>
                    <i className="fa-solid fa-star-of-life"></i>CSS
                  </li>
                  <small>Intermediete</small>
                </div>

                <div className="langus">
                  <li>
                    <i className="fa-solid fa-star-of-life"></i> Bootstrap
                  </li>
                  <small>Experienced</small>
                </div>

                <div className="langus">
                  <li>
                    <i className="fa-solid fa-star-of-life"></i>React js
                  </li>
                  <small>Experienced</small>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-5 backend">
            <h5 className="text-center my-4">Backend Developement</h5>
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="langus">
                  <li>
                    <i className="fa-solid fa-star-of-life"></i>Node js
                  </li>
                  <small>Experienced</small>
                </div>

                <div className="langus">
                  <li>
                    <i className="fa-solid fa-star-of-life"></i>Express js
                  </li>
                  <small>Experienced</small>
                </div>

                <div className="langus">
                  <li>
                    <i className="fa-solid fa-star-of-life"></i>Firebase
                  </li>
                  <small>Experienced</small>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="langus">
                  <li>
                    <i className="fa-solid fa-star-of-life"></i>PHP
                  </li>
                  <small>Intermediete</small>
                </div>

                <div className="langus">
                  <li>
                    <i className="fa-solid fa-star-of-life"></i>MySQL
                  </li>
                  <small>Experienced</small>
                </div>

                <div className="langus">
                  <li>
                    <i className="fa-solid fa-star-of-life"></i>MongoDB
                  </li>
                  <small>Experienced</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Experience;
