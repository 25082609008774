import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="container">
      <footer id="footer">
        <div className="row">
          <div className="col-12 footer-nav">
            <ul className="permalinks">
              <li>
                <a href="#home">Home</a>
              </li>
              <li>
                <a href="#about">About</a>
              </li>
              <li>
                <a href="#experience">Experience</a>
              </li>
              <li>
                <a href="#services">Services</a>
              </li>
              <li>
                <a href="#portfolio">Portfolio</a>
              </li>
              <li>
                <a href="#testimonials">Testimonials</a>
              </li>
              <li>
                <a href="#contact">Contact</a>
              </li>
            </ul>
          </div>
          <div className="col-12 footer-socials">
            <a href="https://www.facebook.com">
              <i className="fa-brands fa-facebook-square"></i>
            </a>
            <a href="https://www.instagram.com">
              <i class="fa-brands fa-instagram-square"></i>
            </a>

            <a href="https://www.twitter.com">
              <i class="fa-brands fa-twitter-square"></i>
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
